<div class="chat-container">
  <div class="chat-header p-2 pb-0 pt-3">
    <div class="d-flex flex-row justify-content-center align-items-center">
      <img
        class="mr-2"
        style="
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #673ab7;
        "
        [src]="selectedChatbot.avatar"
      />
      <div class="d-flex flex-column">
        <span style="font-family: &quot;Raleway&quot;; font-size: large">
          Welcome {{ usersFirstName }}!

          {{ selectedChatbot.name }} here.
        </span>
        <span style="font-family: &quot;Raleway&quot;" class="mat-small">{{
          selectedChatbot.description
        }}</span>
      </div>
    </div>
    <div style="flex: 1"></div>
    <mat-form-field
      [style.width]="isMobile ? '80vw' : null"
      [class]="isMobile ? 'mt-1' : 'mt-3'"
      appearance="outline"
    >
      <mat-label>Chatbot</mat-label>
      <mat-select
        [(ngModel)]="selectedChatbot"
        (selectionChange)="selectChatbot($event)"
      >
        <mat-option
          class="p-2"
          *ngFor="let chatbot of chatbots"
          [value]="chatbot"
        >
          <div class="d-flex flex-row align-items-center">
            <img
              class="mr-2"
              style="
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid #673ab7;
              "
              [src]="chatbot.avatar"
            />
            <div class="d-flex flex-column" style="line-height: 10px">
              <span style="display: block">{{ chatbot.name }}</span>
              <span
                style='display: block; font-size:0.7em font-family: "Raleway"'
                class="mat-small"
              >
                {{ chatbot.description }}
              </span>
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      class="mb-2 ml-2"
      [matTooltip]="'Start a new conversation'"
      mat-icon-button
      color="primary"
      (click)="startNewConversation()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="chat-messages" #chatMessages>
    <div
      *ngFor="let message of conversation"
      class="message"
      [ngClass]="{
        'user-message': message.role === 'user',
        'ai-message': message.role !== 'user'
      }"
    >
      <div class="message-content p-2">
        <div
          style="
            font-family: &quot;Raleway&quot;;
            font-weight: bold;
            font-size: larger;
            gap: 10px;
          "
          class="d-flex flex-row justify-content-start align-items-center"
        >
          <img
            style="
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 2px solid #673ab7;
            "
            [src]="
              message.role == 'user' ? usersAvatar : selectedChatbot.avatar
            "
            alt=""
          />
          <div class="d-flex flex-column">
            <strong>
              {{
                message.role === "user" ? usersFirstName : selectedChatbot.name
              }}:
            </strong>
            <!-- <span style="font-family: &quot;Raleway&quot;" class="mat-small">
              You are talking to {{ selectedChatbot.name }}.
              {{ selectedChatbot.description }}
            </span> -->
          </div>
        </div>
        <hr />
        <div *ngFor="let part of message.splitContent">
          <div *ngIf="part.type === 'text'">
            <span
              [style.font-size]="isMobile ? '' : 'larger'"
              style="
                font-family: &quot;Raleway&quot;;
                font-weight: bolder;
                font-size: larger;
                line-height: 1.5;
              "
              [innerHTML]="part.value"
            ></span>
          </div>
          <app-code-block
            *ngIf="part.type === 'code'"
            [content]="part.value"
          ></app-code-block>
        </div>
      </div>
    </div>
  </div>
  <div class="chat-input p-2">
    <mat-form-field appearance="outline" class="message-input">
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        [(ngModel)]="message"
        [placeholder]="selectedChatbot.description"
        (keyup.enter)="sendMessage()"
      ></textarea>
      <button
        [disabled]="!selectedChatbot.hasVision"
        [matTooltip]="'Upload a file'"
        matSuffix
        mat-icon-button
        color="primary"
        (click)="fileInput.click()"
      >
        <mat-icon>attach_file</mat-icon>
      </button>
    </mat-form-field>

    <input
      hidden
      type="file"
      #fileInput
      (change)="onFileSelected($event)"
      multiple
    />
    <!-- <mat-form-field appearance="outline">
      <mat-label>AI Provider</mat-label>
      <mat-select [(ngModel)]="aiProvider">
        <mat-option value="claude">Claude</mat-option>
        <mat-option value="openai">OpenAI</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput [(ngModel)]="model" placeholder="Enter model name" />
    </mat-form-field> -->
    <button
      class="mb-3"
      mat-icon-button
      color="primary"
      (click)="sendMessage()"
    >
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>
